<template>
  <div class="side-menu">
    <el-scrollbar class="side-menu__scroller">
      <!--      background-color="#0D4278"-->
      <el-menu
          :collapse="isCollapse"
          :collapse-transition="false"
          :default-active="$route.path"
          :unique-opened="true"
          active-text-color="#FFFFFF"
          
          class="el-menu"
          mode="vertical"
          text-color="#5F666C"
          @select="onMenuSelect"
      >
        <MenuItem
            v-for="(item, index) in menuList"
            :key="index"
            :base-path="item.menuurl"
            :item="item"
            class="menu-item"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import MenuItem from "./menu-item.vue";

export default {
  name: "side-menu",
  props: {
    routes: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    MenuItem,
  },
  data() {
    return {
      menuList: [],
      isCollapse: false,
    };
  },
  watch: {
    routes(newVal) {
      console.log(newVal);
      if (newVal && newVal.length > 0) {
        this.menuList = newVal;
      }
    },
  },
  methods: {
    collapse(isCollapse) {
      this.isCollapse = isCollapse;
    },
    initMenuRoute() {
      // console.log(this.routes,'看看有没有菜单');
      this.menuList = this.routes;
    },
    onMenuSelect(index) {
      this.$router.push(index);
    },
  },
  mounted() {
    this.initMenuRoute();
  },
};
</script>

<style lang="less" scoped>
.side-menu {
  padding: 30px 35px;
  height: 100%;
  background-image: url(../../../assets/images/newhome/sideback-on.png);
  background-size: 100% ;
  background-color: black;
  .side-menu__scroller {
    height: 100%;

    /deep/ .el-scrollbar__wrap {
      overflow-x: hidden;
      margin-right: 18px;
    }
  }
  /deep/ .el-menu {
      // overflow-x: hidden;
      background-color: transparent;
    }
  .el-menu {
    border-right-width: 0;
    width: 170px; // 修改后
  }

  .el-menu--collapse {
    width: 40px;

    /deep/ .el-tooltip {
      //background: red;
      position: absolute;
      display: flex !important;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
    }
  }
}
.menu-item{
  margin-bottom: 15px;
}
/deep/ .el-icon-arrow-up, /deep/ .el-icon-arrow-down {
  display: none;
}
</style>
