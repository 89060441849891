<template>
  <div class="easy-router_tab">
    <div class="transform-start"
         @click="transformToLeft">
      <img src="@/assets/images/arrow_left.png" class="arrow-left">
    </div>
    <div class="transform-content">
      <div ref="scrollContent" class="scroll-content">
        <EasyScrollbar ref="scrollbar">
          <div class="inner-content">
            <div
                v-for="(item, index) in routeMenu"
                :key="index"
                :class="{
                active: isActive(item),
              }"
                class="router-item"
                @click="to(item.fullPath)"
            >
              <span>{{ item.meta.title }}</span>
              <!-- v-if="item.name !== 'home'" -->
              <i
                  v-if="index != 0"
                  class="el-icon-close"
                  @click.stop="removeRoute(item)"
              ></i>
            </div>
          </div>
        </EasyScrollbar>
      </div>
    </div>
    <div class="transform-end">
      <div class="transform-end-item" @click="transformToRight">
        <img src="@/assets/images/arrow_right.png" class="arrow-left">
      </div>
      <div class="transform-remove-all"
          @click="removeAllRoute">
        <!--      <i class="el-icon-d-arrow-right"></i>-->
        关闭全部标签
      </div>
    </div>

  </div>
</template>

<script>
import EasyScrollbar from "@/components/scrollbar";
import {mapState} from "vuex";

export default {
  name: "router-tab",
  components: {
    EasyScrollbar,
  },
  data() {
    return {
      routers: [],
    };
  },
  computed: {
    ...mapState("app", ["routeMenu"]),
    scrollContent() {
      return this.$refs.scrollContent;
    },
    scrollbar() {
      return this.$refs.scrollbar;
    },
    currentRoute() {
      return this.$route;
    },
  },
  watch: {
    $route(newRoute) {
      this.$store.commit("app/addRouteMenu", {
        fullPath: newRoute.fullPath,
        hash: "",
        meta: newRoute.meta,
        name: newRoute.name,
        params: newRoute.params,
        path: newRoute.path,
        query: newRoute.query,
      });
      this.$nextTick(() => {
        this.transformToRight();
      });
    },
  },
  mounted() {
    const newRoute = this.$route;
    this.$store.commit("app/addRouteMenu", {
      fullPath: newRoute.fullPath,
      hash: "",
      meta: newRoute.meta,
      name: newRoute.name,
      params: newRoute.params,
      path: newRoute.path,
      query: newRoute.query,
    });
  },
  methods: {
    to(path) {
      this.$router.push(path);
    },
    removeRoute(item) {
      if (item.name === this.$route.name) {
        this.$store.commit("app/removeRoute", item);
        this.$router.back();
      } else {
        this.$store.commit("app/removeRoute", item);
      }
    },
    removeAllRoute() {
      this.$store.commit('app/removeAllRouteMenu');
      const goRoute = window.localStorage.getItem("goRoute")
      this.$router.push(goRoute);
    },
    isActive(route) {
      const isActive = this.$route.matched.some((record) => {
        return record.regex.test(route.path);
      });
      return isActive;
    },
    transformToLeft() {
      this.scrollbar.scrollbarContent.scrollLeft = 0;
    },
    transformToRight() {
      const distance =
          this.scrollbar.scrollbarContent.scrollWidth -
          this.scrollContent.offsetWidth;
      this.scrollbar.scrollbarContent.scrollLeft = distance;
    },
    switchRoute(item) {
      this.$router.push(item.fullPath);
    },
  },
};
</script>

<style lang="less" scoped>
.easy-router_tab {
  // background: #F4F7FC;
  background: #EBF0F9;
  width: 100%;
  position: relative;
  height: 46px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  .transform-start,
  .transform-end {
    //width: 30px;
    height: 30px;
    top: 14px;
    //background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    position: absolute;
  }

  .transform-start {
    // left: 33px;
    left: 15px;
    width: 30px;
    cursor: pointer;
    display: flex;align-items: center;justify-content: center; background: #FFFFFF;

    .arrow-left{
      width: 10px;height: 10px;
    }
  }

  .transform-end {
    right: 15px;

    .transform-end-item{
      width: 30px;height: 30px;cursor: pointer;display: flex;align-items: center;justify-content: center;background: #FFFFFF;

      .arrow-left{
        width: 10px;height: 10px;
      }
    }

    .transform-remove-all {
      width: 96px;height: 29px;background: #0768FD;font-size: 12px;cursor: pointer;
      line-height: 29px; display: flex;align-items: center;justify-content: center;
      color: white;border-radius: 0 0 0 0;opacity: 1;margin-left: 6px;
    }
  }

  .transform-content {
    height: 100%;
    position: absolute;
    // left: 69px;
    left: 50px;
    right: 171px;
    margin-top: 6px;
    // overflow-x: auto;
    // overflow-y: hidden;

    .scroll-content {
      width: 100%;
      height: 100%;

      .inner-content {
        white-space: nowrap;

        .router-item {
          display: inline-block;
          box-sizing: border-box;
          padding: 7px 10px;
          border: 1px solid white;
          background: white;
          //border-radius: 4px;
          color: #525456;
          margin: 8px 5px 0 0;
          cursor: pointer;

          &:hover {
            border-color: #0768FD;
            color: #0768FD;
          }

          &.active {
            background: #0768FD;
            color: #fff;
            border-color: #0768FD;
          }

          i {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
</style>
