import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/index.less'
// import '../theme/index.css'
import RouterTab from 'vue-router-tab'
import 'vue-router-tab/dist/lib/vue-router-tab.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/icons'
import IconButton from '@/components/IconButton'
import EasyCard from '@/components/Card'
import NProgress from 'nprogress/nprogress'
import 'nprogress/nprogress.css'

import VCA from '@vue/composition-api'
import JsonEditorVue from 'json-editor-vue'

Vue.use(RouterTab)

Vue.use(ElementUI)

Vue.component('icon-button', IconButton);
Vue.component('easy-card', EasyCard);

Vue.use(VCA);
Vue.use(JsonEditorVue, {
    // 全局 props & attrs（单向数据流）
});

router.beforeEach((to, from, next) => {
    NProgress.start()
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!localStorage.getItem('token')) {
            next({
                path: '/login',
                query: {redirect: to.fullPath}
            })
        } else {
            next()
        }
    } else {
        if (localStorage.getItem('token')) {
            if (to.path !== '/login') {
                next();
            } else {
                next({
                    path: '/'
                });
            }
        } else {
            next();
        }
    }
})

router.afterEach(() => {
    NProgress.done()
})

Vue.config.productionTip = false

window.$vue = new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')

