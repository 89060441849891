<template>
  <div class="easy-scrollbar">
    <Scrollbar ref="verticalScrollbar" mode="vertical"/>
    <Scrollbar ref="horizontalScrollbar" mode="horizontal"/>
    <div
        ref="scrollbarContent"
        class="easy-scrollbar-content hide-native-scrollbar"
        @scroll="onScroll"
    >
      <div class="easy-scrollbar__inner">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Scrollbar from "./bar";

export default {
  name: "easy-scrollbar",
  components: {
    Scrollbar,
  },
  data() {
    return {};
  },
  computed: {
    verticalScrollbar() {
      return this.$refs.verticalScrollbar;
    },
    horizontalScrollbar() {
      return this.$refs.horizontalScrollbar;
    },
    scrollbarContent() {
      return this.$refs.scrollbarContent;
    },
  },
  methods: {
    // 监听scrollbarContent的滚动事件
    // 并相对应的改变滚动条的位置
    onScroll() {
      const scrollbarContent = this.scrollbarContent;

      const scrollTop = scrollbarContent.scrollTop;
      const translateY = (scrollTop * 100) / scrollbarContent.clientHeight;
      this.verticalScrollbar.doScroll(translateY, "Y");

      const scrollLeft = scrollbarContent.scrollLeft;
      const translateX = (scrollLeft * 100) / scrollbarContent.clientWidth;
      this.horizontalScrollbar.doScroll(translateX, "X");
    }, // 当内容变动时调用此方法重新计算滚动条的高度或宽度
    // scrollToLeft() {
    //   this.scrollbarContent.scrollLeft = 0;
    // },
    // scrollToRight () {
    //   this.scrollbarContent.scrollLeft =
    // },
    doResize() {
      this.verticalScrollbar.initVerticalScrollBar();
      this.horizontalScrollbar.initHorizontalScrollBar();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.doResize();
    });
  },
};
</script>

<style lang="less" scoped>
.easy-scrollbar {
  height: 100%;
  position: relative;
  overflow: hidden;

  .easy-scrollbar-content {
    overflow: scroll;
    height: 100%;

    &.hide-native-scrollbar {
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
</style>
