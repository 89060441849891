import { render, staticRenderFns } from "./SvgIcon.vue?vue&type=template&id=4116b09d&scoped=true"
import script from "./SvgIcon.vue?vue&type=script&lang=js"
export * from "./SvgIcon.vue?vue&type=script&lang=js"
import style0 from "./SvgIcon.vue?vue&type=style&index=0&id=4116b09d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4116b09d",
  null
  
)

export default component.exports