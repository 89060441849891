<template>
  <div
      ref="scrollbar"
      :class="scrollBarClass"
      @mousedown="onScrollBarMouseDown($event)"
  >
    <div
        ref="scrollbarThumb"
        :style="verticalScrollBarStyle"
        class="easy-scrollbar-thumb"
        @mousedown="onScrollThumbMouseDown($event)"
    ></div>
  </div>
</template>

<script>
import {off, on} from "./dom";

export default {
  name: "scrollbar",
  props: {
    mode: {
      type: String,
      default: "horizontal",
    },
  },
  data() {
    return {
      verticalScrollBarHeightPercentage: 0,
      horizontalScrollBarWidthPercentage: 0,

      axisName: "Y",
      direction: "top",
      client: "clientY",
      offset: "offsetHeight",
      scroll: "scrollTop",
      contentScroll: "scrollHeight",
    };
  },
  computed: {
    scrollBarClass() {
      return `easy-scrollbar-bar is-${this.mode}`;
    },
    scrollBar() {
      return this.$refs.scrollbar;
    },
    scrollBarThumb() {
      return this.$refs.scrollbarThumb;
    },
    // 还可以继续优化 去掉纵向以及横向的属性 改为统一的字段来描述
    verticalScrollBarStyle() {
      const style = {};
      const percentage =
          this.mode === "vertical"
              ? this.verticalScrollBarHeightPercentage
              : this.horizontalScrollBarWidthPercentage;
      const styleName = this.mode === "vertical" ? "height" : "width";

      style[styleName] = percentage < 100 ? percentage + "%" : "";
      return style;
    },
  },
  methods: {
    initMode() {
      const mode = this.mode;
      const map = {
        vertical: {
          axisName: "Y",
          direction: "top",
          client: "clientY",
          offset: "offsetHeight",
          scroll: "scrollTop",
          contentScroll: "scrollHeight",
        },
        horizontal: {
          axisName: "X",
          direction: "left",
          client: "clientX",
          offset: "offsetWidth",
          scroll: "scrollLeft",
          contentScroll: "scrollWidth",
        },
      };

      const properties = map[mode];

      this.axisName = properties["axisName"];
      this.direction = properties["direction"];
      this.client = properties["client"];
      this.offset = properties["offset"];
      this.scroll = properties["scroll"];
      this.contentScroll = properties["contentScroll"];
    },
    // 滚动
    doScroll(distance, axis) {
      this.scrollBarThumb.style.transform = `translate${axis}(${distance}%)`;
    },

    // 计算纵向滚动条的高度
    initVerticalScrollBar() {
      const scrollbarContent = this.$parent.scrollbarContent;
      const heightPercentage =
          (scrollbarContent.clientHeight * 100) / scrollbarContent.scrollHeight;
      this.verticalScrollBarHeightPercentage = heightPercentage;
    },

    // 计算横向滚动条的宽度
    initHorizontalScrollBar() {
      const scrollbarContent = this.$parent.scrollbarContent;
      const widthPercentage =
          (scrollbarContent.clientWidth * 100) / scrollbarContent.scrollWidth;
      this.horizontalScrollBarWidthPercentage = widthPercentage;
    },

    onScrollBarMouseDown(e) {
      const scrollbarContent = this.$parent.scrollbarContent;

      const direction = this.direction,
          client = this.client,
          offset = this.offset,
          scroll = this.scroll,
          contentScroll = this.contentScroll;

      const offsetVal = Math.abs(
          e.target.getBoundingClientRect()[direction] - e[client]
      );
      const thumbHalf = this.scrollBarThumb[offset] / 2;
      const thumbPositionPercentage =
          ((offsetVal - thumbHalf) * 100) / this.scrollBar[offset];

      scrollbarContent[scroll] =
          (thumbPositionPercentage * scrollbarContent[contentScroll]) / 100;
    },

    onScrollThumbMouseDown(e) {
      if (e.ctrlKey || e.button === 2) {
        return;
      }

      this.startDrag(e);
      this[this.axisName] =
          e.currentTarget[this.offset] -
          (e[this.client] -
              e.currentTarget.getBoundingClientRect()[this.direction]);
    },

    mouseMoveDocumentHandler(e) {
      const scrollbarContent = this.$parent.scrollbarContent;

      if (this.cursorDown === false) return;

      const prevVal = this[this.axisName];
      if (!prevVal) return;

      const offset =
          (this.scrollBar.getBoundingClientRect()[this.direction] -
              e[this.client]) *
          -1;
      // 滑块的位置=滚动条的高度-鼠标第一次落下时位置
      const thumbClickPosition = this.scrollBarThumb[this.offset] - prevVal;
      const thumbPositionPercentage =
          ((offset - thumbClickPosition) * 100) / this.scrollBar[this.offset];

      scrollbarContent[this.scroll] =
          (thumbPositionPercentage * scrollbarContent[this.contentScroll]) / 100;
    },

    mouseUpDocumentHandler() {
      this.cursorDown = false;
      this[this.axisName] = 0;
      off(document, "mousemove", this.mouseMoveDocumentHandler);
      document.onselectstart = null;
    },

    startDrag(e) {
      e.stopImmediatePropagation();
      this.cursorDown = true;

      on(document, "mousemove", this.mouseMoveDocumentHandler);
      on(document, "mouseup", this.mouseUpDocumentHandler);
      document.onselectstart = () => false;
    },
  },
  created() {
    this.initMode();
  },
};
</script>

<style lang="less" scoped>
</style>
