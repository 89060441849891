<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>

<style lang="less" scoped>
#app {
  height: 100vh;
}
</style>
