const state = {
    centerViews: {
        _CENTERID_DEFAULT: {
            visitedViews: [], // 显示的路由标签
            activeView: null, // 高亮当前路由标签
        }
    },
    affixTabs: [{path: '/home', name: 'home', title: '首页'}], // 固定的路由标签
    defaultCenterId: '_CENTERID_DEFAULT',
    cachedViews: [], // 路由标签缓存，后续用于刷新操作
    activeDate: String(new Date().getTime()) // 用于模块页面刷新
};

const affixTabsNames = state.affixTabs.map(item => item.name);

const mutations = {
    // 页面模块刷新
    SET_ACTIVE_DATE: (state, activeDate) => {
        state.activeDate = activeDate
    },
    // 添加标签
    ADD_VISITED_VIEW: (state, {tab}) => {
        const targetCenterId = state.defaultCenterId;
        if (!state.centerViews[targetCenterId]) {
            state.centerViews[targetCenterId] = {
                visitedViews: [tab],
            };
            return;
        }
        if (state.centerViews[targetCenterId].visitedViews.some(v => v.path === tab.path)) return;
        state.centerViews[targetCenterId].visitedViews.push(tab);
    },
    // 添加标签缓存
    ADD_CACHED_VIEW: (state, {tab}) => {
        const name = (tab && tab.name) || '';
        if (!name) return;
        if (state.cachedViews.includes(name)) return;
        state.cachedViews.push(name);
    },
    // 删除当前标签
    DEL_VISITED_VIEW: (state, {tab, centerId}) => {
        const targetCenterId = centerId || state.defaultCenterId;
        for (let i = 0; i < state.centerViews[targetCenterId].visitedViews.length; i += 1) {
            const view = state.centerViews[targetCenterId].visitedViews[i];
            if (view.path === tab.path) {
                state.centerViews[targetCenterId].visitedViews.splice(i, 1);
                break;
            }
        }
    },
    // 删除当前标签缓存
    DEL_CACHED_VIEW: (state, name) => {
        const index = state.cachedViews.indexOf(name);
        if (index > -1) {
            state.cachedViews.splice(index, 1);
        }
    },
    // 删除其他标签
    DEL_OTHERS_VISITED_VIEWS: (state, {tab, centerId}) => {
        const targetCenterId = centerId || state.defaultCenterId;
        const tabInAffixTabs = state.affixTabs.some(item => item.path === tab.path);
        const visitedViews = tabInAffixTabs ? [...state.affixTabs] : [...state.affixTabs, tab];
        state.centerViews = {
            ...state.centerViews,
            [targetCenterId]: {
                ...state.centerViews[targetCenterId],
                visitedViews,
            },
        };
    },
    // 删除其他标签缓存
    DEL_OTHERS_CACHED_VIEWS: (state, {tab}) => {
        const name = (tab && tab.name) || '';
        const index = state.cachedViews.indexOf(name);
        if (index > -1) {
            state.cachedViews = [...state.cachedViews.slice(index, index + 1), ...affixTabsNames];
        } else {
            state.cachedViews = [...affixTabsNames];
        }
    },
    // 删除所有标签
    DEL_ALL_VISITED_VIEWS: (state, centerId) => {
        const targetCenterId = centerId || state.defaultCenterId;
        state.centerViews = {
            ...state.centerViews,
            [targetCenterId]: {
                visitedViews: [...state.affixTabs],
            },
        };
    },
    // 删除所有标签缓存
    DEL_ALL_CACHED_VIEWS: (state) => {
        state.cachedViews = [...affixTabsNames];
    },
    // 设置选中标签
    SET_CENTERACTIVE_VIEW: (state, {centerId, tab} = {}) => {
        const targetCenterId = centerId || state.defaultCenterId;
        state.centerViews[targetCenterId].activeView = tab;
    }
};

const actions = {
    // 新增当前路由标签和标签缓存
    addView({dispatch}, payload) {
        dispatch('addVisitedView', payload);
        dispatch('addCachedView', payload);
    },
    // 新增当前路由标签
    addVisitedView({commit}, payload) {
        commit('ADD_VISITED_VIEW', payload);
        commit('SET_CENTERACTIVE_VIEW', payload);
    },
    // 新增当前路由标签缓存
    addCachedView({commit}, payload) {
        commit('ADD_CACHED_VIEW', payload);
    },
    // 删除当前路由标签和标签缓存
    delView({dispatch, state}, payload) {
        return new Promise((resolve) => {
            dispatch('delVisitedView', payload);
            const {centerId, tab: {name}} = payload;
            dispatch('delCachedView', name);
            const targetCenterId = centerId || state.defaultCenterId;
            resolve({
                visitedViews: [...state.centerViews[targetCenterId].visitedViews],
            });
        });
    },
    // 删除当前路由标签
    delVisitedView({commit, state}, payload) {
        return new Promise((resolve) => {
            commit('DEL_VISITED_VIEW', payload);
            const targetCenterId = payload.centerId || state.defaultCenterId;
            resolve([...state.centerViews[targetCenterId].visitedViews]);
        });
    },
    // 删除当前路由标签缓存
    delCachedView({commit, state}, name) {
        return new Promise((resolve) => {
            commit('DEL_CACHED_VIEW', name);
            resolve([...state.cachedViews]);
        });
    },
    // 删除其他路由标签和标签缓存
    delOthersViews({dispatch, state}, payload) {
        return new Promise((resolve) => {
            dispatch('delOthersVisitedViews', payload);
            dispatch('delOthersCachedViews', payload);
            const targetCenterId = payload.centerId || state.defaultCenterId;
            resolve({
                visitedViews: [...state.centerViews[targetCenterId].visitedViews],
            });
        });
    },
    // 删除其他路由标签
    delOthersVisitedViews({commit, state}, payload) {
        return new Promise((resolve) => {
            commit('DEL_OTHERS_VISITED_VIEWS', payload);
            const targetCenterId = payload.centerId || state.defaultCenterId;
            resolve([...state.centerViews[targetCenterId].visitedViews]);
        });
    },
    // 删除其他路由标签缓存
    delOthersCachedViews({commit, state}, payload) {
        return new Promise((resolve) => {
            commit('DEL_OTHERS_CACHED_VIEWS', payload);
            resolve([...state.cachedViews]);
        });
    },
    // 删除所有路由标签和标签缓存
    delAllViews({dispatch, state}, centerId) {
        const targetCenterId = centerId || state.defaultCenterId;
        return new Promise((resolve) => {
            dispatch('delAllVisitedViews', targetCenterId);
            dispatch('delAllCachedViews', targetCenterId);
            resolve({
                visitedViews: [...state.centerViews[targetCenterId].visitedViews],
            });
        });
    },
    // 删除所有路由标签
    delAllVisitedViews({commit, state}, centerId) {
        const targetCenterId = centerId || state.defaultCenterId;
        return new Promise((resolve) => {
            commit('DEL_ALL_VISITED_VIEWS', targetCenterId);
            resolve([...state.centerViews[targetCenterId].visitedViews]);
        });
    },
    // 删除所有路由标签缓存
    delAllCachedViews({commit, state}) {
        return new Promise((resolve) => {
            commit('DEL_ALL_CACHED_VIEWS');
            resolve([...state.cachedViews]);
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
