export function dateFormat(fmt, date) {
    let ret;
    const opt = {
        "y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString() // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(
                ret[1],
                ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
            );
        }
    }
    return fmt;
}

function isArrayLike(o) {
    if (o &&
        typeof o === 'object' &&
        isFinite(o.length) &&
        o.length >= 0 &&
        o.length === Math.floor(o.length) &&
        o.length < 4294967296)
        return true;
    else
        return false;
}

function isType(target) {
    var types = {
        '[object Boolean]': 'boolean',
        '[object Number]': 'number',
        '[object String]': 'string',
        '[object Function]': 'function',
        '[object Array]': 'array',
        '[object Date]': 'date',
        '[object RegExp]': 'regexp',
        '[object Object]': 'object',
        '[object Error]': 'error'
    };

    if (target === null) {
        return target + "";
    }

    return typeof target === 'object' || typeof target === 'function' ?
        types[Object.prototype.toString.call(target)] || 'object' : typeof target;
}

export function isNumber(target) {
    return isType(target) === 'number';
}

export function isArray(target) {
    if (Array.isArray) {
        return Array.isArray(target);
    }

    return target instanceof Array && isType(target) === 'array';
}

export function isFunction(target) {
    return isType(target) === 'function';
}

function isObject(target) {
    return isType(target) === 'object';
}

export function isUndefined(target) {
    return typeof target === 'undefined';
}

export function isEmpty(target) {
    var _isArray = isArray(target) && target.length === 0,
        _isObject = isObject(target) && target === null,
        _isString = isType(target) === 'string' && target === '';

    return _isArray || _isObject || _isString;
}

export function isEqual(target1, target2) {
    if (!isEmpty(target1) && !isEmpty(target2)) {
        return target1 === target2;
    }
    return false;
}

export function convertArrayLikeToArray(o) {
    if (!o) {
        return [];
    }
    if (o.nodeType === 1) {
        return [o];
    }
    if (isArrayLike(o)) {
        return Array.prototype.concat.apply([], o).slice(0);
    }
    return o;
}

export function extend() {
    var args = arguments;
    var options, name, src, srcType, copy, copyIsArray, clone,
        target = args[0] || {},
        i = 1,
        length = args.length,
        deep = false;

    if (typeof target === 'boolean') {
        deep = target;
        target = args[i] || {};
        i++;
    }
    if (typeof target !== 'object' && typeof target !== 'function') {
        target = {};
    }
    if (i === length) {
        target = this;
        i--;
    }
    for (; i < length; i++) {
        if ((options = args[i]) !== null) {
            for (name in options) {
                src = target[name];
                copy = options[name];

                if (target === copy) {
                    continue;
                }

                srcType = isArray(src) ? 'array' : typeof src;

                if (deep && copy && ((copyIsArray = isArray(copy)) || typeof copy === 'object')) {
                    if (copyIsArray) {
                        copyIsArray = false;
                        clone = src && srcType === 'array' ? src : [];
                    } else {
                        clone = src && srcType === 'object' ? src : {};
                    }
                    target[name] = Function.prototype.call(this, deep, clone, copy);
                } else if (copy !== undefined) {
                    target[name] = copy;
                }
            }
        }
    }
    return target;
}

export function objectToQueryString(obj) {
    if (!obj) {
        return '';
    }

    return Object.keys(obj).map(function (key) {
        return "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(obj[key]));
    }).join('&');
}