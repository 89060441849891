<template>
  <div ref="base-chart" class="easy-chart"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "easy-chart",
  props: {
    option: {
      type: Object,
      default() {
        return {};
      },
    },
    map: {
      type: Object,
      default() {
        return {};
      },
    },
    lazy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chartOption: {},
    };
  },
  computed: {
    baseChart() {
      return this.$refs["base-chart"];
    },
  },
  watch: {
    option(newVal) {
      if (newVal) {
        this.render(newVal);
      }
    },
  },
  methods: {
    render(echartsOption) {
      this.chart.setOption(echartsOption);
    },
    dispatchAction(options) {
      this.chart.dispatchAction(options);
    },
    onResize() {
      this.chart.resize();
    },
    registMap(map) {
      if (map) {
        const mapType = map["type"];
        const config = {};
        config[mapType] = map[mapType];
        echarts.registerMap(map.name, config);
      }
    },
    init() {
      this.chart = echarts.init(this.baseChart);
      this.chart.setOption(this.option);

      // window.addEventListener("resize", this.onResize, false);
      return this;
    },
  },
  created() {
    // this.registMap();
  },
  mounted() {
    if (!this.lazy) {
      this.chart = echarts.init(this.baseChart);
      this.chart.setOption(this.option);

      window.addEventListener("resize", this.onResize, false);
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize, false);
  },
};
</script>
