<template>
  <div class="app">
    <div class="app-top">
      <div style="height: 100%;
            display: flex; justify-content: center; align-items: center;">
        <div :class="{'is-collapse': isCollapse}" class="top-right" style="background-color: #0768FD;height: 100%;">
          <!-- <img v-if="!isCollapse" class="logo" src="@/assets/images/admin-logo-new.png"/>
          <img v-if="isCollapse" class="logo-collapse" src="@/assets/logo.png"/> -->
          <img v-if="!isCollapse" class="logo" src="@/assets/images/newhome/admin-logo.png"/>
          <img v-if="isCollapse" class="logo-collapse" src="@/assets/images/newhome/bit_logo2.png"/>
        </div>
        <div>
          <!--          <i class="el-icon-s-fold" style="font-size: 20px" @click="collapseMenu" v-if="!isCollapse"></i>-->
          <!--          <i class="el-icon-s-unfold" style="font-size: 20px" @click="collapseMenu" v-if="isCollapse"></i>-->
          <img v-if="isCollapse" src="@/assets/images/fold.png" class="collapse-img"
               @click="collapseMenu">
          <img v-if="!isCollapse" src="@/assets/images/unfold.png" class="collapse-img"
               @click="collapseMenu">
        </div>
      </div>
      <div class="user-area">
        <router-link class="logout-button" style="font-weight: bold;" to="/screen" target="_blank" v-if="screenSwitch != 1">大屏</router-link>
        <div class="avator"></div>
        <div class="user-name">{{ userName }}</div>
        <div class="grey-bar"></div>
        <!--        <div class="switch-platform">-->
        <!--          <el-dropdown trigger="click" @command="changePlatform">-->
        <!--            <span>比特泊车管理后台 <i class="el-icon-caret-bottom"  style="color: #0768FD"></i></span>-->

        <!--            <el-dropdown-menu slot="dropdown">-->
        <!--              <el-dropdown-item-->
        <!--                :command="item.permissionCode"-->
        <!--                v-for="(item, index) in dropdownList"-->
        <!--                :key="index"-->
        <!--                >{{ item.permissionName }}</el-dropdown-item-->
        <!--              >-->
        <!--            </el-dropdown-menu>-->

        <!--          </el-dropdown>-->

        <!--        </div>-->
        <div class="logout-button" @click="doLogout">退出
          <!--          <i class="el-icon-switch-button"  style="color: #0768FD"></i>-->
          <img src="@/assets/images/logout.png" class="logout-img">
        </div>
      </div>
    </div>
    <div class="app-content">
      <div :class="{ 'is-collapse': isCollapse }" class="sidebar">
        <SideMenu ref="sideMenu" :routes="routes"/>
      </div>
      <div :class="{ 'is-collapse': isCollapse }" class="body-content">
        <router-tab/>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"/>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"/>
      </div>
    </div>
  </div>
</template>

<script>
// import Sidebar from "./components/sidebar";
import {mapState} from "vuex";
import RouterTab from "./components/router-tab";
import SideMenu from "./components/side-menu";
// import Routes from "@/router/routes";

export default {
  name: "layout",
  components: {
    // Sidebar,
    RouterTab,
    SideMenu,
  },
  data() {
    return {
      userName: "",
      isAdmin: true,
      hasBusiness: true,
      current: "0",
      isCollapse: false,
      routes: [],
      platformList: [],
      currentPlatform: "",
      screenSwitch:null,
    };
  },
  computed: {
    ...mapState("tabsView", ["activeDate"]),
    sideMenu() {
      return this.$refs.sideMenu;
    },
    dropdownList() {
      const platformList = this.platformList;
      return platformList.filter(
          (item) => item.permissionCode !== this.currentPlatform
      );
    },
  },
  created() {
    this.currentPlatform = window.localStorage.getItem("currentPlatform");
    this.screenSwitch = window.localStorage.getItem("screenSwitch");
    this.userName = window.localStorage.getItem("userName");
    // 获取所有的非当前平台的平台列表
    const permissions = JSON.parse(window.localStorage.getItem("permissions"));
    this.platformList = permissions.filter(
        (item) => item.permissionCode.length === 3
    );

    // 转换数组为树形结构
    const treePermissions = this.switchListToTree(
        permissions.filter((item) => item.isNavigation === 1),
        ""
    );
    console.log('layouts',treePermissions, this.currentPlatform)

    this.routes = this.doSort(
        treePermissions
        //     .filter(
        //     (item) => item.permissionCode === this.currentPlatform
        // )[0].children
    );
    console.log('layouts',2)
  },
  methods: {
    doSort(dataList) {
      console.log('doSort',dataList)
      dataList.sort((item1, item2) => {
        return item1.displayOrder - item2.displayOrder;
      });

      dataList.forEach((item) => {
        if (item.children && item.children.length > 0) {
          item.children = this.doSort(item.children);
        }
      });
      return dataList;
    },
    convertToRouteList(permissionList) {
      if (!permissionList) {
        return [];
      }

      return permissionList.map((item) => {
        return {
          code: item.permissionCode,
          meta: {
            title: item.permissionName,
          },
          path: item.menuurl,
          icon: item.extendInfo || "",
        };
      });
    },
    switchListToTree(dataList, parentId) {
      const menuObject = {};
      dataList.forEach((item) => {
        item.children = [];
        menuObject[item.id] = item;
      });

      return dataList.filter((item) => {
        if (item.parentId !== parentId) {
          menuObject[item.parentId].children.push(item);
          return false;
        }
        return true;
      });
    },
    //点击切换平台
    changePlatform(e) {
      if (e === "002") {
        this.$store.commit("app/clearRouteMenu");
        this.$router.push("/share");
        this.savePlatform("002");
      } else if (e === "003") {
        this.$store.commit("app/clearRouteMenu");
        this.$router.push("/net");
        this.savePlatform("003");
      } else if (e === "004") {
        this.$store.commit("app/clearRouteMenu");
        this.$router.push("/data_board");
        // this.savePlatform("004");
      }
    },
    collapseMenu() {
      this.isCollapse = !this.isCollapse;
      this.sideMenu.collapse(this.isCollapse);
    },
    savePlatform(platformCode) {
      window.localStorage.setItem("currentPlatform", platformCode);
    },
    doLogout() {
      this.$confirm("此操作将退出登录, 是否继续?", "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        window.localStorage.clear();
        window.sessionStorage.clear();
        this.$store.commit("app/clearRouteMenu");
        this.$router.push("/login");
      });
    },
    handleCommand(command) {
      if (command === "doLogout") {
        this.doLogout();
      }
    },
  },
  mounted() {
  },
};
</script>

<style lang="less" scoped>
@import "@/styles/mixins.less";

.app {
  .clearfix();
  position: relative;
  height: 100vh;
  width: 100vw;

  .app-top {
    height: 60px;
    // background: #FFFFFF;
    background-color: #0768FD;
    box-shadow: 0 0 8px rgb(0 0 0 / 20%);
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .collapse-img{
      width: 24px;
      height: 24px;
      margin-left: 33px;
    }

    .top-right {
      width: 246px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: #0768FD;

      &.is-collapse {
        width: 106px !important;
      }

      .s-fold {
        color: #0768FD;
        font-size: 24px;
        cursor: pointer;

        &.is-collapse {
          transform: rotate(180deg);
        }
      }

      .logo {
        // width: 148px;
        width: 132px;
        // height: 30px;
        //margin: 0 60px 0 30px;
      }

      .logo-collapse {
        width: 36px;
        // height: 37px;
      }
    }

    .user-area {
      display: flex;
      flex-direction: row;
      align-items: center;

      .avator {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background: #ffebe5;
        //margin-right: 10px;
        background: url("../assets/images/avatar.png");
        background-repeat: no-repeat;
        background-size: contain;
      }

      .user-name{
        font-size: 16px;
        // color: #343A40;
        color: white;
        margin-left: 16px;
      }

      .grey-bar{
        width: 1px;
        height: 26px;
        background: #D9D9D9;
        margin: 0 24px;
      }

      .switch-platform {
        height: 30px;
        //background: #ffebe5;
        border-radius: 30px;
        padding: 0 20px;
        line-height: 30px;
        margin-right: 20px;
        cursor: pointer;
      }

      .logout-button {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 35px;
        // color: #0768FD;
        color: white;
        font-size: 16px;
      }
      .logout-img{
        width: 20px;
        height: 18px;
        margin-left: 12px;
      }
    }
  }

  .app-content {
    position: relative;
    height: calc(100vh - 60px);
    // background: #fff;
    background-color: #EBF0F9;
    z-index: 99;

    .sidebar {
      transition: width 0.28s;
      // width: 246px;
      //background-color: rgb(22, 36, 58);
      //background-color: #0D4278;
      background-color: white;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 1001;
      overflow: hidden;

      &.is-collapse {
        // width: 140px;

        .el-submenu__title {
          //background: red;
        }

        .is-active {
          //background: red;
          .el-submenu__title {
            background-color: #0768FD !important; //选中背景色
          }
        }
      }
    }
  }

  .body-content {
    height: 100%;
    transition: margin-left 0.28s;
    margin-left: 246px;
    position: relative;
    // background: #F4F7FC;
    background-color: #EBF0FA;
    overflow-x: hidden;
    overflow-y: auto;

    &.is-collapse {
      margin-left: 106px;
    }

    .navbar {
      height: 40px;
      overflow: hidden;
      position: relative;
      background: #fff;
      // box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
      // transition: width 0.28s;
      // display: flex;
      // flex-direction: row;
      // align-items: center;
      // justify-content: flex-start;

      /deep/ .el-tabs__header {
        background: transparent;
        border: none;

        .el-tabs__item {
          background: transparent;
          height: 30px;
          line-height: 30px;
          //border: 1px solid red;
          margin-right: 5px;
          border-radius: 3px;
          margin-top: 5px;
          padding: 0 10px;
        }
      }
    }

    .main-content {
      overflow-y: auto;
      flex: 1;
      height: 100px;
    }
  }
}
/deep/ .el-scrollbar__wrap{
  overflow: hidden;
  margin-right: 0 !important;
}
</style>
