export default {
    name: 'scroll-table-column',
    props: {
        prop: String,
        label: String,
        width: Number,
        align: {
            type: String,
            default: 'center'
        }
    },
    data() {
        return {
            cellRender: null
        }
    },
    created() {
        this.cellRender = (h, row) => {
            if (this.$scopedSlots.default) {
                return h('div', null, [
                    this.$scopedSlots.default(row)
                ])
            }

            return h('span', null, row[this.prop]);
        }
    },
    render() {
        //Todo
    }
}