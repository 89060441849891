const routes = [
    // 停车场管理
    {
        path: '/net/parking_manage',
        name: 'net_parking_manage',
        component: () => import('@/pages/net/parking/index'),
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '停车场管理',
            icon: "el-icon-bell"
        },
        // redirect: '/share/parking_manage/share_parkingList',
        children: [
            {
                path: 'net_parkingList',
                name: 'net_parkingList',
                component: () => import('@/pages/net/parking/list'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '停车场列表',
                    icon: "el-icon-bell",
                    keepAlive: true
                },
            },
            {
                path: 'net_parking_add',
                name: 'net_parking_add',
                component: () => import('@/pages/net/parking/add'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    hidden: true,
                    title: '停车场信息维护',
                },
            },
            {
                path: 'net_parking_detail',
                name: 'net_parking_detail',
                component: () => import('@/pages/net/parking/detail'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    hidden: true,
                    title: '车场详情',
                },
            },
            {
                path: 'net_parking_addprice',
                name: 'net_parking_addprice',
                component: () => import('@/pages/parking/add_price'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    hidden: true,
                    title: '计费规则',
                },
            }
        ]
    },
    {
        path: '/net/parking_order',
        name: 'net_parking_order',
        component: () => import('@/pages/net/parking_order'),
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '停车订单',
            icon: "el-icon-bell",
            keepAlive: true
        },
    },
    {
        path: '/net/parking_order/parking_order_detail',
        name: 'parking_order_detail',
        component: () => import('@/pages/net/parking_order/parking_order_detail'),
        meta: {
            requiresAuth: true,
            isAdmin: true,
            hidden: true,
            title: '停车订单详情',
            icon: "el-icon-bell"
        },
    }
];

export default routes;
