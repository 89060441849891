import request from '@/utils/request'
import {objectToQueryString} from '@/utils/utils';

//查询套餐列表并分页
export function getLeaseInfoListForPage(parkingId, pageSize, leaseStatus, leaseTitle, buyWay, pageNo, isExport, exportSize) {
    return request.get('/leaseController/getLeaseInfoListForPage?parkingId=' + parkingId + '&pageSize=' + pageSize + '&leaseStatus=' + leaseStatus + '&leaseTitle=' + leaseTitle + '&buyWay=' + buyWay + '&pageNo=' + pageNo + '&isExport=' + isExport + '&exportSize=' + exportSize)
}

//根据主键编辑套餐
export function updateLeaseInfo(id) {
    return request.get('/leaseController/updateLeaseInfo?id=' + id)
}

//根据id查询套餐详情
export function getLeaseInfoById(id) {
    return request.get('/leaseController/getLeaseInfoById?id=' + id)
}

//停用或启用套餐
export function disableOrStartUsingLeaseInfo(id, status) {
    return request.get('/leaseController/disableOrStartUsingLeaseInfo?id=' + id + '&status=' + status)
}

//添加长租套餐
export function addOrUpdateLeaseInfo(leaseinfo) {
    return request.post('/leaseController/addOrUpdateLeaseInfo', leaseinfo)
}

//根据ID获取订单详情
export function getLeaseOrderById(id) {
    return request.get('/leaseController/getLeaseOrderById?id=' + id)
}

//订单审核
export function leaseOrderVerify(id, reason, auditStatus) {
    return request.get('/leaseController/leaseOrderVerify?id=' + id + '&reason=' + reason + '&auditStatus=' + auditStatus)
}

//获取长租订单列表并分页
export function getLeaseOrderListForPage(autoId, leaseId, pageSize, pageNo, orderCode) {
    return request.get('/leaseController/getLeaseOrderListForPage?autoId=' + autoId + '&leaseId=' + leaseId + '&pageSize=' + pageSize + '&pageNo=' + pageNo + '&orderCode=' + orderCode)
}

//执行或关闭活动套餐
export function startUsingOrDisable(id, status) {
    return request.get('/leaseController/startUsingOrDisable?id=' + id + '&status=' + status)
}

//添加活动套餐
export function saveLeaseDisCount(data) {
    return request.post('/leaseController/saveLeaseDisCount', data)
}

//根据活动ID查询参加活动的长租订单
export function getLeaseDisCountOrder(activityId, page, size) {
    return request.get('/leaseController/getLeaseDisCountOrder?activityId=' + activityId + '&page=' + page + '&size=' + size)
}

//根据ID获取长租活动详情
export function getLeaseDisCountById(id) {
    return request.get('/leaseController/getLeaseDisCountById?id=' + id)
}

//查询长租活动并分页
export function getLeaseDisCountListForPage(parkingId, pageSize, leaseId, activityName, activityStatus, pageNo, isExport, exportSize) {
    return request.get('/leaseController/getLeaseDisCountListForPage?parkingId=' + parkingId + '&pageSize=' + pageSize + '&leaseId=' + leaseId + '&activityName=' + activityName + '&activityStatus=' + activityStatus + '&pageNo=' + pageNo + '&isExport=' + isExport + '&exportSize=' + exportSize)
}

//按车场Id查询当前车场套餐
export function getLeaseInfoByParkingLotId(parkingLotId) {
    return request.get('/leaseController/getLeaseInfoByParkingLotId?parkingLotId=' + parkingLotId)
}

//解析长租模板
export function doUploadAnalysisExcel(params) {
    return request.post('/leaseController/doUploadAnalysisExcel', params);
}

//批量导入长租信息
export function batchImportLeaseList(params) {
    return request.post('/leaseController/batchImportLeaseList', params)
}

//查询长租订单列表
export function getLeaseOrderForPage(params) {
    return request.get('/leaseController/getLeaseOrderForPage?' + objectToQueryString(params));
}

//创建长租订单
export function createLeaseOrder(data) {
    return request.post('/leaseController/createLeaseOrder', data);
}

export function publishCouponByQRCode(params) {
    return request.post('openController/publishCouponByQRCode', params);
}


export function leaseRenewalOrder(params) {
    return request.post('leaseController/leaseRenewalOrder', params);
}

//删除长租订单
export function deleteLeaseOrderById(id) {
    return request.post('leaseController/deleteLeaseOrderById?id=' + id);
}

//编辑长租订单
export function updateLeaseOrder(data) {
    return request.post('/leaseController/updateLeaseOrder', data);
}
