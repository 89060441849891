<template>
  <div ref="disScrollTable" class="dis-scroll-table">
    <div v-if="this.$slots.head" class="frozen-header">
      <slot name="head"></slot>
    </div>
    <div v-else class="frozen-header">
      <table class="dis-table">
        <thead>
        <tr>
          <th
              v-for="(column, index) in columnList"
              :key="index"
              class="frozen-head-th"
          >
            <div
                :class="thCellClass"
                :style="{ width: `${column.width}px`, textAlign: column.align }"
                class="scroll-table-cell"
            >
              {{ column.label }}
            </div>
          </th>
        </tr>
        </thead>
      </table>
    </div>

    <div
        ref="scrollContent"
        class="scroll-content"
        @mouseout="onMouseOut"
        @mouseover="onMouseOver"
    >
      <div ref="scrollArea" class="scroll-content-inner">
        <table class="dis-table">
          <tbody>
          <tr v-for="(item, d) in data" :key="d">
            <td
                v-for="(column, index) in columnList"
                :key="index"
                class="scroll-column"
            >
              <div
                  :class="tdCellClass"
                  :style="{
                    width: `${column.width}px`,
                    textAlign: column.align,
                  }"
                  class="scroll-table-cell"
              >
                <cell-render :render="column.cellRender" :row="item"/>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "dis-scroll-table",
  props: {
    data: Array,
    thCellClass: {
      type: String,
      default: "",
    },
    tdCellClass: {
      type: String,
      default: "",
    },
    speed: {
      type: Number,
      default: 0.3,
    },
    isLoop: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      cellWidth: 0,
      columnList: [],
      animateFrame: null,
      count: 1,
      scrollTop: 0,
    };
  },
  components: {
    "cell-render": {
      functional: true,
      render: (h, ctx) => {
        return ctx.props.render(h, ctx.props.row);
      },
    },
  },
  computed: {
    disScrollTable() {
      return this.$refs.disScrollTable;
    },
    scrollContent() {
      return this.$refs.scrollContent;
    },
    scrollArea() {
      return this.$refs.scrollArea;
    },
    propsListener() {
      const {tdCellClass, isLoop, speed} = this;
      return {
        tdCellClass,
        isLoop,
        speed,
      };
    },
  },
  watch: {
    propsListener(newVal) {
      if (newVal) {
        this.initLoop();
      }
    },
  },
  methods: {
    initColumnWidth() {
      const columnComponents = this.$slots.default;
      if (!columnComponents || columnComponents.length === 0) {
        return;
      }

      this.columnList = columnComponents.map((item) => {
        const {prop, label, width, align, cellRender} =
            item.componentInstance;
        return {
          prop,
          label,
          width,
          align,
          cellRender,
        };
      });

      const hasWidthColumns = this.columnList.filter((item) => item.width);

      const isColumnWidth = hasWidthColumns.length > 0;
      const disScrollTableWidth = this.disScrollTable.clientWidth;
      const size = this.columnList.length;
      let cellWidth = 0;

      if (!isColumnWidth) {
        cellWidth = disScrollTableWidth / size;

        this.columnList.forEach((item) => {
          item.width = cellWidth;
        });
      } else {
        const freeWidth =
            disScrollTableWidth -
            hasWidthColumns.reduce(function (total, curr) {
              return total + curr.width;
            }, 0);

        if (freeWidth > 0) {
          cellWidth = freeWidth / (size - hasWidthColumns.length);
          this.columnList
              .filter((item) => !item.width)
              .forEach((item) => {
                item.width = cellWidth;
              });
        }
      }
    },
    onMouseOver() {
      if (!this.isLoop) {
        return;
      }
      window.cancelAnimationFrame(this.animateFrame);
    },
    onMouseOut() {
      if (!this.isLoop) {
        return;
      }
      this.beginScroll();
    },
    initLoop() {
      if (!this.isLoop) {
        return;
      }

      const scrollAreaHeight = this.scrollArea.clientHeight;
      const scrollContentHeight = this.scrollContent.clientHeight;

      if (scrollAreaHeight > scrollContentHeight) {
        this.scrollArea.innerHTML += this.scrollArea.innerHTML;
        this.beginScroll();
      }
    },
    beginScroll() {
      const scrollAreaHeight = this.scrollArea.offsetHeight;

      const render = () => {
        this.animateFrame = window.requestAnimationFrame(() => {
          if (Math.abs(this.scrollTop) > scrollAreaHeight / 2) {
            this.scrollTop = 0;
          } else {
            this.scrollTop -= this.speed;
          }
          this.scrollArea.style.transform = `translateY(${this.scrollTop}px)`;

          render();
        });
      };

      render();
    },
  },
  mounted() {
    this.initColumnWidth();

    this.$nextTick(() => {
      this.initLoop();
    });
  },
};
</script>
<style lang="less" scoped>
.dis-table {
  width: 100%;
  color: #fff;

  // tr:nth-child(even) {
  //   background: #1a3364;
  // }

  .frozen-head-th,
  .scroll-column {
    padding: 10px 0;
  }

  .scroll-table-cell,
  .scroll-table-cell {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.dis-scroll-table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;

  .frozen-header {
    height: 34px;
    box-sizing: border-box;
    border-bottom: 1px solid #1459c3;
    background: linear-gradient(90deg,
    rgba(13, 44, 75, 0) 0%,
    #0d2c4b 49%,
    rgba(13, 44, 75, 0) 98%);
    opacity: 1;
    border: 1px solid;
    border-image: radial-gradient(circle,
    rgba(0, 255, 233, 1),
    rgba(75, 126, 254, 0)) 1 1;
  }

  .scroll-content {
    overflow: hidden;
    box-sizing: border-box;
    flex: 1;
  }
}
</style>
