import axios from 'axios'
import {Message, MessageBox} from "element-ui";

const axiosConfig = {
    baseURL: '/opapi',
    timeout: 40000,
    headers: {
        'Content-Type': 'application/json'
    }
};

const service = axios.create(axiosConfig);

const requestInterceptor = config => {
    const token = window.localStorage.getItem('token');

    if (token) {
        config.headers['token'] = token
    }
    return config
};

const responseInterceptor = response => {
    if (response.config.responseType === 'blob') {
        if (response.data.errorCode) {
            return response.data
        } else {
            return response;
        }
    }
    if (response.status === 200 || response.statusText === 'OK') {
        if (response.data.code === 30) {
            return response.data
        } else if (response.data.code === 405) {
            if (window.localStorage.getItem('token')) {
                MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
                    confirmButtonText: '重新登录',
                    callback: () => {
                        window.localStorage.clear()
                        window.$vue.$router.push('/login')
                    }
                });
            } else {
                window.localStorage.clear()
                window.$vue.$router.push('/login')
            }
        } else {
            Message.warning(response.data.message)
        }
    }
};

service.interceptors.request.use(
    requestInterceptor,
    error => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    responseInterceptor,
    error => {
        return Promise.reject(error)
    }
)

export default service;

const mockService = axios.create(Object.assign(axiosConfig, {
    baseURL: '/mockapi'
}));

mockService.interceptors.request.use(requestInterceptor, error => {
    return Promise.reject(error)
});

mockService.interceptors.response.use(responseInterceptor,
    error => {
        return Promise.reject(error)
    }
);

const payService = axios.create(Object.assign(axiosConfig, {
    baseURL: '/payapi'
}));

payService.interceptors.request.use(requestInterceptor, error => {
    return Promise.reject(error)
});

payService.interceptors.response.use(responseInterceptor,
    error => {
        return Promise.reject(error)
    }
);

const exportService = axios.create({
    baseURL: '/opapi',
    timeout: 40000,
    headers: {
        'Content-Type': 'application/json'
    },
    responseType: 'blob'
});
exportService.interceptors.request.use(requestInterceptor, error => {
    return Promise.reject(error)
});
exportService.interceptors.response.use((response) => {
    if (response.config.responseType === 'blob') {
        if (response.data.errorCode) {
            return response.data
        } else {
            return response;
        }
    } else {
        return response;
    }
}, error => {
    return Promise.reject(error)
});

export {
    mockService,
    payService,
    exportService
};
