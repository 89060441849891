import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

// 配置模块化文件导入
const modulesFiles = require.context('./modules', true, /\.js$/);
const modules = modulesFiles.keys().reduce((objModule, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
    const value = modulesFiles(modulePath);
    return {
        ...objModule,
        [moduleName]: value.default,
    };
}, {});

export default new Vuex.Store({
    modules,
    plugins: [
        createPersistedState({
            storage: window.sessionStorage
        })
    ]
});