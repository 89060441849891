const hide = ($dom) => {
    $dom.style.display = 'none';
    return $dom;
}

const addClass = ($dom, className) => {
    $dom.classList.add(className);
    return $dom;
}

const append = ($parent, $child) => {
    $parent.appendChild($child);
    return $parent;
}

const remove = ($parent, $child) => {
    $parent.removeChild($child);
}

class Popup {
    constructor(options) {
        this.options = Object.assign({
            text: '请稍等...',
            mode: 'message',
            time: 2 * 1000
        }, options);

        this.init();
    }

    init() {
        const $options = this.options;

        this.$popup = addClass(document.createElement('div'), 'easy-popup');
        const $popupContent = addClass(document.createElement('div'), 'easy-popup__content');

        let $node = document.createElement('div');
        let popupContentClassName = 'easy-popup__mask';

        if ($options.mode === 'message') {
            $node = document.createTextNode($options.text);
            popupContentClassName = 'easy-popup__message';
            addClass(this.$popup, 'message');
        }

        append(
            document.body,
            append(
                this.$popup,
                append(
                    addClass($popupContent, popupContentClassName),
                    $node
                )
            )
        )

        if ($options.mode === 'message' && $options.time > 0) {
            this.hide($options.time);
        }
    }


    hide(time = 200) {
        const $popup = this.$popup;

        setTimeout(() => {
            remove(document.body, hide($popup));
        }, time);
    }
}


export const showLoading = () => {
    return new Popup({
        mode: 'mask'
    });
};

export const showMessage = (msg, time = 2000) => {
    return new Popup({
        mode: 'message',
        text: msg,
        time: time
    });
};
