<template>
  <div class="app">
    <div class="app-top">
      <div class="top-right">
        <img class="logo" src="@/assets/images/admin-logo-new.png"/>
        <svg-icon
            :class="{ 'is-collapse': isCollapse }"
            class="s-fold"
            iconClass="s-fold"
            @click.native="collapseMenu"
        />
      </div>
      <div class="user-area">
        <div class="avator"></div>
        <div class="switch-platform">
          <el-dropdown trigger="click" @command="changePlatform">
            <span>比特泊车联网平台 <i class="el-icon-caret-bottom" style="color: #0768FD"></i></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                  v-for="(item, index) in dropdownList"
                  :key="index"
                  :command="item.permissionCode"
              >{{ item.permissionName }}
              </el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="logout-button" @click="doLogout">
          <i class="el-icon-switch-button" style="color: #0768FD"></i>
        </div>
      </div>
    </div>
    <div class="app-content">
      <div :class="{ 'is-collapse': isCollapse }" class="sidebar">
        <SideMenu ref="sideMenu" :routes="routes"/>
      </div>
      <div :class="{ 'is-collapse': isCollapse }" class="body-content">
        <router-tab/>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"/>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"/>
      </div>
    </div>
  </div>
</template>

<script>
// import Sidebar from "./components/sidebar";
import {mapState} from "vuex";
import RouterTab from "./components/router-tab";
import SideMenu from "./components/side-menu";
import Routes from "@/router/net_routes";

export default {
  name: "net-layout",
  components: {
    // Sidebar,
    RouterTab,
    SideMenu,
  },
  data() {
    return {
      userName: "",
      isAdmin: true,
      hasBusiness: true,
      current: "0",
      isCollapse: false,
      routes: Routes,
      platformList: [],
      currentPlatform: "",
    };
  },
  computed: {
    ...mapState("tabsView", ["activeDate"]),
    sideMenu() {
      return this.$refs.sideMenu;
    },
    dropdownList() {
      const platformList = this.platformList;
      return platformList.filter(
          (item) => item.permissionCode !== this.currentPlatform
      );
    },
  },
  created() {
    this.currentPlatform = window.localStorage.getItem("currentPlatform");

    // 获取所有的非当前平台的平台列表
    const permissions = JSON.parse(window.localStorage.getItem("permissions"));
    this.platformList = permissions.filter(
        (item) => item.permissionCode.length === 3
    );

    // 转换数组为树形结构
    const treePermissions = this.switchListToTree(
        permissions.filter((item) => item.isNavigation === 1),
        ""
    );

    this.routes = this.doSort(
        treePermissions.filter(
            (item) => item.permissionCode === this.currentPlatform
        )[0].children
    );
  },
  methods: {
    doSort(dataList) {
      dataList.sort((item1, item2) => {
        return item1.displayOrder - item2.displayOrder;
      });

      dataList.forEach((item) => {
        if (item.children && item.children.length > 0) {
          item.children = this.doSort(item.children);
        }
      });
      return dataList;
    },
    convertToRouteList(permissionList) {
      if (!permissionList) {
        return [];
      }

      return permissionList.map((item) => {
        return {
          code: item.permissionCode,
          meta: {
            title: item.permissionName,
          },
          path: item.menuurl,
          icon: item.extendInfo || "",
        };
      });
    },
    switchListToTree(dataList, parentId) {
      const menuObject = {};
      dataList.forEach((item) => {
        item.children = [];
        menuObject[item.id] = item;
      });

      return dataList.filter((item) => {
        if (item.parentId !== parentId) {
          menuObject[item.parentId].children.push(item);
          return false;
        }
        return true;
      });
    },
    savePlatform(platformCode) {
      window.localStorage.setItem("currentPlatform", platformCode);
    },
    //点击切换平台
    changePlatform(e) {
      if (e === "001") {
        this.$store.commit("app/clearRouteMenu");
        this.$router.push("/home");
        this.savePlatform("001");
      } else if (e === "002") {
        this.$store.commit("app/clearRouteMenu");
        this.$router.push("/share");
        this.savePlatform("002");
      } else if (e === "data_board") {
        this.$store.commit("app/clearRouteMenu");
        this.$router.push("/data_board");
      }
    },
    collapseMenu() {
      this.isCollapse = !this.isCollapse;
      this.sideMenu.collapse(this.isCollapse);
    },
    doLogout() {
      this.$confirm("此操作将退出登录, 是否继续?", "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        window.localStorage.clear();
        window.sessionStorage.clear();
        this.$store.commit("app/clearRouteMenu");
        this.$router.push("/login");
      });
    },
    handleCommand(command) {
      if (command === "doLogout") {
        this.doLogout();
      }
    },
  },
  mounted() {
  },
};
</script>

<style lang="less" scoped>
@import "@/styles/mixins.less";

.app {
  .clearfix();
  position: relative;
  height: 100vh;
  width: 100vw;

  .app-top {
    height: 60px;
    background: #FFFFFF;
    box-shadow: 0 0 8px rgb(0 0 0 / 20%);
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .top-right {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .s-fold {
        color: #0768FD;
        font-size: 24px;
        cursor: pointer;

        &.is-collapse {
          transform: rotate(180deg);
        }
      }

      .logo {
        width: 120px;
        height: 41px;
        margin: 0 60px 0 30px;
      }
    }

    .user-area {
      display: flex;
      flex-direction: row;
      align-items: center;

      .avator {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background: #ffebe5;
        margin-right: 10px;
        background: url("../assets/images/avatar.png");
        background-repeat: no-repeat;
        background-size: contain;
      }

      .switch-platform {
        height: 30px;
        //background: #ffebe5;
        border-radius: 30px;
        padding: 0 20px;
        line-height: 30px;
        margin-right: 20px;
        cursor: pointer;
      }

      .logout-button {
        margin-right: 35px;
        color: #fff;
        font-size: 20px;
      }
    }
  }

  .app-content {
    position: relative;
    height: calc(100vh - 60px);
    background: #fff;
    z-index: 99;

    .sidebar {
      transition: width 0.28s;
      width: 200px;
      //background-color: rgb(22, 36, 58);
      //background-color: #0D4278;
      background-color: white;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 1001;
      overflow: hidden;

      &.is-collapse {
        width: 40px;
      }
    }
  }

  .body-content {
    height: 100%;
    transition: margin-left 0.28s;
    margin-left: 200px;
    position: relative;
    background: #F4F7FC;
    overflow-x: hidden;
    overflow-y: auto;

    &.is-collapse {
      margin-left: 64px;
    }

    .navbar {
      height: 40px;
      overflow: hidden;
      position: relative;
      background: #fff;
      // box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
      // transition: width 0.28s;
      // display: flex;
      // flex-direction: row;
      // align-items: center;
      // justify-content: flex-start;

      /deep/ .el-tabs__header {
        background: transparent;
        border: none;

        .el-tabs__item {
          background: transparent;
          height: 30px;
          line-height: 30px;
          border: 1px solid red;
          margin-right: 5px;
          border-radius: 3px;
          margin-top: 5px;
          padding: 0 10px;
        }
      }
    }

    .main-content {
      overflow-y: auto;
      flex: 1;
      height: 100px;
    }
  }
}
</style>
