<template>
  <div :title="title" class="icon-button">
    <template v-if="this.$slots.default">
      <slot/>
    </template>
    <svg-icon v-else :iconClass="icon" className="op-icon"/>
  </div>
</template>

<script>
export default {
  name: "icon-button",
  props: {
    icon: String,
    title: String,
  },
};
</script>

<style lang="less" scoped>
.icon-button {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: #0768FD;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 5px;

  .op-icon {
    font-size: 20px;
    color: #fff;
  }
}
</style>
