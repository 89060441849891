const routes = [
    // 停车场管理
    {
        path: '/share/parking_manage',
        name: 'share_parking_manage',
        component: () => import('@/pages/share/parking/index'),
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '停车场管理',
            icon: "el-icon-bell"
        },
        // redirect: '/share/parking_manage/share_parkingList',
        children: [
            {
                path: 'share_parkingList',
                name: 'share_parkingList',
                component: () => import('@/pages/share/parking/list'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '停车场列表',
                    icon: "el-icon-bell",
                    keepAlive: true
                },
            },
            {
                path: 'share_person_manage',
                name: 'share_person_manage',
                component: () => import('@/pages/share/parking/person_manage'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '巡检人员',
                    icon: "el-icon-bell"
                },
            },
            {
                path: 'share_parking_add',
                name: 'share_parking_add',
                component: () => import('@/pages/share/parking/add'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    hidden: true,
                    title: '停车场信息维护',
                },
            },
            {
                path: 'share_parking_detail',
                name: 'share_parking_detail',
                component: () => import('@/pages/share/parking/detail'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    hidden: true,
                    title: '停车场详情',
                },
            },
            {
                path: 'share_parking_addprice',
                name: 'share_parking_addprice',
                component: () => import('@/pages/parking/add_price'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    hidden: true,
                    title: '计费规则',
                },
            }
        ]
    },
    {
        path: '/share/parking_order',
        name: 'share_parking_order',
        component: () => import('@/pages/share/parking_order'),
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '停车订单',
            icon: "el-icon-bell",
            keepAlive: true
        },
    },
    {
        path: '/share/parking_order/parking_order_detail',
        name: 'share_parking_order_detail',
        component: () => import('@/pages/share/parking_order/parking_order_detail'),
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '停车订单详情',
            icon: "el-icon-bell",
            hidden: true
        },
    },
    {
        path: '/share/appointment_order',
        name: 'share_appointment_order',
        component: () => import('@/pages/share/appointment_order'),
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '预约订单',
            icon: "el-icon-bell",
            keepAlive: true
        },
    },
    {
        path: '/share_operation_manage',
        name: 'share_operation_manage',
        component: () => import('@/pages/share/operation'),
        children: [
            {
                path: '/share/operation_manage/blackwhitelist',
                name: 'share_blackwhitelist',
                component: () => import('@/pages/share/operation/blackwhitelist/index'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '黑白名单',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
        ],
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '运营管理',
            icon: "el-icon-bell"
        }
    }
];

export default routes;